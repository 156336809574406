import { SET_TOASTR } from 'src/actions/toastr';

export type toastrStateType = {
  isOpen: boolean;
  title?: string;
  message: string;
  variant?: 'success' | 'error' | 'info';
};

const initialState: toastrStateType = {
  isOpen: false,
  title: '',
  message: '',
  variant: 'success',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOASTR:
      return {
        ...state,
        isOpen: true,
        ...(action.data as Partial<toastrStateType>),
      };
    default:
      return state;
  }
};
